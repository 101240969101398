body {
	margin: 0;
	font-optical-sizing: auto;
	font-weight: 700;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 17px;
	padding: 0;
	box-sizing: border-box;
}
body::-webkit-scrollbar {
	width: var(--sb-size);
}

body::-webkit-scrollbar-track {
	background: white;
	border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
	background: var(--primary-100);
	border: 2px solid var(--primary-950);
}

@supports not selector(::-webkit-scrollbar) {
	body {
		scrollbar-color: var(--primary-100) white;
	}
}
a {
	text-decoration: none;
	color: black;
}
a:hover {
	color: black;
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
	padding: 0;
	margin: 0;
}
input:focus,
select:focus,
textarea:focus {
	outline: 0px;
}
ul {
	list-style-type: none;
}
.paid {
	--bs-table-bg: rgba(var(--bs-success-rgb), 0.5);
}

.not-paid {
	--bs-table-bg: rgba(var(--bs-danger-rgb), 0.5);
}
.row,
.col-6,
.col-4 {
	margin: 0;
	padding: 0;
}
/* Header */
.dropdown-menu {
	background-color: black !important;
	border: 0 !important;
	padding: 0 !important;
	overflow: hidden;
}
.dropdown-menu a {
	padding: 10px 20px !important;
	color: #ffffff !important;
	margin: 0 !important;
}
.mobile-header {
	display: none;
}

/* SHOP */
.sidebar_item {
	margin-bottom: 35px;
}
.sidebar_item h4 {
	color: #1c1c1c;
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 25px;
}
.sidebar_item ul {
	margin: 0;
	padding: 0;
}
.sidebar_item ul li {
	list-style: none;
	display: flex;
}

.sidebar_item ul li a {
	font-size: 16px;
	color: #000000;
	line-height: 45px;
	display: block;
	font-family: 'Open Sans', sans-serif;
}
.sidebar_item ul li img {
	width: 25px;
	object-fit: contain;
	margin-left: 20px;
}
.rating {
	margin: 5px 0;
}
.rating i {
	font-size: 12px;
	color: #001840;
}
.rating span {
	margin-left: 10px;
}
/* END OF SHOP */

/* NEWSLATTER */
.subscribe-section {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.226)), url('https://www.elpoderbanilejo.com/v6/images/poder3/SHOP-NOW-Module17392391_TSK_Home-page-banners-for-Herbalife-com-1022X400.jpg') no-repeat scroll center / cover;
	padding: 100px 0 100px;
}
.subscribe-head h2 {
	color: #fff;
	font-size: 30px;
	letter-spacing: 0;
	line-height: 30px;
	margin: 0 0 13px;
	text-transform: uppercase;
}
.subscribe-head p {
	color: #fff;
	font-size: 18px;
	line-height: 22px;
	margin: 0 0 39px;
}
.subscribe-head {
	text-align: center;
}
.form-section input[type='email'] {
	border: 0 none;
	border-radius: 25px;
	color: #9b9b9b;
	font-size: 16px;
	font-style: normal;
	height: 51px;
	letter-spacing: 0;
	text-align: center;
	width: 460px;
}
.form-section input[type='submit'] {
	background: #1cb803;
	border: 0 none;
	border-radius: 25px;
	color: #fff;
	height: 52px;
	letter-spacing: 0;
	margin-left: -60px;
	padding: 0 58px;
	text-transform: uppercase;
	font-size: 12px;
}

/* SINGLE PRODUCT */
.single-product {
	margin-top: 50px;
	margin-bottom: 50px;
}
.single-image {
	margin-right: 20px;
	height: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.single-image img {
	width: 90%;
	height: 700px;
	object-fit: contain;
}
.product-name {
	font-size: 25px;
	font-weight: 600;
	line-height: 22px;
	margin-bottom: 30px;
}

.display-flex {
	display: flex;
}
.product-info {
	width: 100%;
}
.product-dtl p {
	line-height: 24px;
	color: #7a7a7a;
}
.product-count {
	border: 2px solid #f3f3f3;
	border-radius: 5px;
	margin-top: 40px;
}
.product-count .flex-box {
	padding: 15px 25px;
	border-bottom: 2px solid #f3f3f3;
}
.product-count .flex-box h6 {
	font-size: 16px;
}
.product-count .flex-box span {
	font-weight: 600;
	color: #000000;
}
.product-count .flex-box select {
	background-color: #f3f3f3;
	width: 100px;
	height: 40px;
	text-align: center;
	border: 0px;
	border-radius: 5px;
}

/* LOGIN */
.login-center {
	padding: 50px 0;
}

/* Input */
input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='number'] {
	width: 100%;
	padding: 20px 20px;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	color: #8a8a8a;
	background-color: #fff;
}
/* PROFILE */
.author-card {
	position: relative;
}
.author-card-cover {
	height: 100px;
	width: 100%;
	background-size: cover;
	background-image: url('https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png');
}
.author-card-profile {
	padding: 20px 10px;
}
.author-card-avatar {
	padding: 0 20px;
}
.author-card-avatar img {
	width: 100px;
	height: 100px;
	object-fit: contain;
	border-radius: 100px;
	margin-top: -60%;
	box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}
.wizard .nav button {
	padding: 15px 20px;
	font-size: 13px;
	background-color: #ffffff;
	color: black;
	width: 100%;
	text-align: start;
	border: 0;
	text-transform: uppercase;
	border-top: 1px solid #e4e4e4;
}
.wizard .nav .active {
	background-color: #e9f8ff;
	color: black;
}
/* orders */
.link {
	color: var(--primary-600);
	font-size: 15px;
}
.link:hover {
	color: var(--primary-800);
}
.table-responsive {
	width: 100%;
}
/* CART */
.cart-iterm {
	background-color: white;
	box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
	position: relative;
}

.remove-button {
	background-color: red;
	width: 25px;
	height: 25px;
	border-radius: 100px;
	position: absolute;
	top: -10px;
	left: -10px;
	font-size: 12px;
	color: #ffffff;
	cursor: pointer;
}
.cart-image img {
	width: 100%;
	height: 150px;
	object-fit: contain;
}
.cart-text a h4,
.cart-price h4 {
	font-size: 17px;
	font-weight: bold;
}
.cart-text p {
	margin-top: 10px;
	font-size: 15px;
	color: #7a7a7a;
}
.cart-qty select {
	width: 100%;
	font-size: 15px;
	padding: 15px;
	border: 1px solid #c5c5c5;
	border-radius: 5px;
}
.cart-qty h6,
.cart-price h6 {
	margin-bottom: 15px;
	color: #8c8c8c;
	font-size: 13px;
	text-transform: uppercase;
}
.total {
	margin-top: 50px;
	margin-bottom: 10px;
	text-align: end;
	padding: 0 30px;
}
.total .sub {
	color: #8c8c8c;
	font-size: 20px;
	text-transform: uppercase;
	margin-right: 20px;
}
.total .total-price {
	font-size: 20px;
	color: #000000;
	font-weight: bold;
}
.cart-buttons {
	margin: 90px 0;
}

/* Payment */
.order-detail p {
	margin-top: 2px;
}
.order-detail {
	padding: 30px 0;
	background-color: var(--primary-100);
}
.order-detail h5 {
	margin-bottom: 6px;
}
.order-box {
	border-radius: 100px;
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
}
.order-product {
	margin: 40px 0;
	border-bottom: 2px solid #f3f3f3;
	position: relative;
}
.order-product h4 {
	font-size: 13px;
	margin-bottom: 10px;
}
.order-product img {
	width: 100%;
	height: 100px;
	object-fit: contain;
}
.table-bordered {
	background-color: #f3f3f3;
}

/* Pagination */
.pagination .page-link {
	padding: 10px 30px;
	color: black;
}
.page-item.active .page-link {
	background-color: black;
	border: 1px solid black;
}

.contact-ig {
	animation: contact-ig .5s ease-out infinite alternate;
}

@keyframes contact-ig {
	0%,
	20% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-10px);
	}
}